import React from 'react';
import FlexBox from '../../atoms/FlexBox/FlexBox';
import { withTranslation, WithTranslation } from 'react-i18next';

import illustration from '../../../assets/illustrations/analytics.svg';

import './_analytics.scss';
import Title from '../../atoms/Title/Title';
import Text from '../../atoms/Text/Text';

interface AnalyticsProps extends WithTranslation {}

const Analytics: React.FunctionComponent<AnalyticsProps> = ({ t }) => {
  return (
    <FlexBox className="container--responsive">
      <FlexBox
        className="analytics_wrapper p-1"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexWrap="wrap"
      >
        <FlexBox className="analytics_left" flexDirection="column" width="100%">
          <Title
            size="large"
            variant="h2"
            className=" color--primary text-left"
          >
            {t('component.analytics.title')}
          </Title>
          <Text
            className="color--tertiary text-left mt-1"
            size="small"
            lighting="light"
          >
            {t('component.analytics.description')}
          </Text>
        </FlexBox>
        <FlexBox
          className="analytics_right"
          flexDirection="column"
          width="100%"
        >
          <img
            className="analytics_illustration"
            src={illustration}
            alt="illustration analytics"
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(Analytics);
