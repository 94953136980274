import React from 'react';
import '../../../sass/components/_flexboxs.scss';

interface FlexBoxProps {
  height?: string;
  width?: string;
  className?: string;
  flex?: '1' | '2' | '3' | '4' | '5';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'wrap' | 'no-wrap' | 'wrap-reverse';
  gap?: string;
  children?: React.ReactNode;
}

const FlexBox: React.FC<FlexBoxProps> = ({
  height,
  width,
  className,
  justifyContent,
  alignItems,
  flexDirection,
  gap,
  flexWrap,
  children,
}) => {
  const classes = `flexbox ${
    justifyContent ? `justify-${justifyContent} ` : ''
  }${alignItems ? `align-${alignItems} ` : ''}${
    flexDirection ? `${flexDirection} ` : ''
  }${flexWrap ? `${flexWrap} ` : ''}`;

  return (
    <div
      className={className ? `${classes} ${className}` : classes}
      style={{ width: width, height: height, gap: gap }}
    >
      {children}
    </div>
  );
};

export default FlexBox;
