import React, { useEffect, useState } from 'react';

import i18n from '../../../i18/i18n';

import './_switchLanguage.scss';

type SwitchLanguageProps = {};

const SwitchLanguage: React.FunctionComponent<SwitchLanguageProps> = () => {
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    let currentLanguage = localStorage.getItem('i18nextLng');
    currentLanguage && setLanguage(currentLanguage);
  });

  const changeLanguage = (language: string) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
    <select
      className="switchLanguage_dropdown"
      value={language}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        changeLanguage(e.target.value)
      }
    >
      <option className="option" key={'en'} value="en">
        󠁧🇺🇸
      </option>
      <option className="option" key={'fr'} value="fr">
        🇫🇷
      </option>
    </select>
  );
};

export default SwitchLanguage;
