import React from 'react';

import '../../../sass/components/_buttons.scss';

type LinkButtonProps = {
  className?: string;
  variant: 'primary' | 'secondary' | 'tertiary' | 'primary-effect';
  size: 'small' | 'medium' | 'large';
  href: string;
  target?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  variant,
  size,
  href,
  target,
  className,
  onClick,
}) => {
  return (
    <a
      className={`button button--${variant} button--${size} ${className}`}
      href={href}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default LinkButton;
