import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import FlexBox from '../../atoms/FlexBox';

import logo from '../../../assets/logos/logo_black.svg';
import facebookIcon from '../../../assets/icons/icons-facebook.png';
import twitterIcon from '../../../assets/icons/icons-twitter.png';
import instagramIcon from '../../../assets/icons/icons-instagram.png';

import '../../../sass/layout/_footer.scss';
import Text from '../../atoms/Text/Text';

interface FooterProps extends WithTranslation {}

const Footer: React.FC<FooterProps> = ({ t }) => {
  return (
    <FlexBox
      className="footer_wrapper background--primary p-1"
      flexDirection="column"
      width="100%"
    >
      <FlexBox
        className="footer_branding pt-2"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="column"
        height="50%"
      >
        <img src={logo} alt="logo" />
        <FlexBox className="mt-2" gap="20px">
          <img src={facebookIcon} alt="icon_social_network" />
          <img src={twitterIcon} alt="icon_social_network" />
          <img src={instagramIcon} alt="icon_social_network" />
        </FlexBox>
      </FlexBox>

      <FlexBox
        className="footer_informations"
        justifyContent="space-between"
        flexDirection="column"
        height="50%"
      >
        <FlexBox className="footer_informations_copyright">
          <Text className="color--secondary mt-1 underline" size="small">
            {t('component.footer.copyright')}
          </Text>
        </FlexBox>
        <FlexBox
          className="footer_informations_credentials"
          justifyContent="flex-end"
          flexDirection="column-reverse"
          gap="20px"
        >
          <Text size="small" lighting="extralight">
            contact@followww.me
          </Text>
          <Text size="small" lighting="extralight">
            {t('component.footer.CGU')}
          </Text>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(Footer);
