import React from 'react';

type TextProps = {
  className?: string;
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  size: 'ultralarge' | 'extralarge' | 'large' | 'medium' | 'small';
  lighting?: 'light' | 'extralight';
  width?: string;
  children?: React.ReactNode;
};

const Title: React.FC<TextProps> = ({
  className,
  variant,
  size,
  lighting,
  width,
  children,
}) => {
  const getVariant = (variant: string) => {
    switch (variant) {
      case 'h1':
        return 'h1';
      case 'h2':
        return 'h2';
      case 'h3':
        return 'h3';
      case 'h4':
        return 'h4';
      case 'h5':
        return 'h5';
      default:
        return 'h1';
    }
  };
  const Variant = getVariant(variant);
  return (
    <Variant
      className={
        lighting && size
          ? `heading--${size}--${lighting} ${className}`
          : `heading--${size} ${className}`
      }
      style={{ width: width }}
    >
      {children}
    </Variant>
  );
};

export default Title;
