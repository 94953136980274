import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

import SwitchLanguage from '../../molecules/SwitchLanguage/SwitchLanguage';

import FlexBox from '../../atoms/FlexBox';

import logo from '../../../assets/logos/logo_white.svg';

import '../../../sass/layout/_nav.scss';
import Text from '../../atoms/Text/Text';
import LinkButton from '../../atoms/LinkButton';

interface NavProps extends WithTranslation {}
interface Size {
  width: number;
  height: number;
}

const Nav: React.FC<NavProps> = ({ t }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    return handleResize();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (size?.width > 850 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size?.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  return (
    <nav className="nav p-1">
      <FlexBox
        className={'nav_content'}
        alignItems="center"
        justifyContent="space-between"
        height="100%"
      >
        <Link className={'nav_content__log'} to="/">
          <img className="logo--nav" src={logo} alt="logo" />
        </Link>
        <FlexBox
          className={`nav_content_nav text-center ${
            menuOpen && size?.width < 850 ? 'isMenu' : ''
          }`}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <FlexBox className="links_box mb-2" flexDirection="column" gap="20px">
            <Link to="/faq" onClick={menuToggleHandler}>
              <Text className="color--primary" size="small">
                {t('component.nav.links.faq')}
              </Text>
            </Link>

            <Link to="/pricing" onClick={menuToggleHandler}>
              <Text className=" color--primary" size="small">
                {t('component.nav.links.pricing')}
              </Text>
            </Link>

            <Link to="/contact-us" onClick={menuToggleHandler}>
              <Text className="color--primary" size="small">
                {t('component.nav.links.contactUs')}
              </Text>
            </Link>

            <FlexBox gap="10px" alignItems="center">
              <LinkButton
                href={`${process.env.VITE_PREFIX_APP}/login`}
                variant="secondary"
                size="small"
              >
                <Text className="color--primary" size="small">
                  {t('component.nav.cta.login')}
                </Text>
              </LinkButton>
              <LinkButton
                variant="primary"
                size="small"
                href={`${process.env.VITE_PREFIX_APP}/signup`}
              >
                <Text className="color--secondary" size="small">
                  {t('component.nav.cta.signup')}
                </Text>
              </LinkButton>
              {size.width > 700 && <SwitchLanguage />}
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox
          className={'nav_content_toggle'}
          alignItems="center"
          gap="10px"
        >
          {size.width < 700 && !menuOpen ? <SwitchLanguage /> : null}
          {!menuOpen ? (
            <BiMenuAltRight
              className="color--primary"
              onClick={menuToggleHandler}
            />
          ) : (
            <AiOutlineClose
              className="color--primary"
              onClick={menuToggleHandler}
            />
          )}
        </FlexBox>
      </FlexBox>
    </nav>
  );
};

export default withTranslation()(Nav);
