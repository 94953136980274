import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './languages/english/translations.json';
import translationFrench from './languages/french/translations.json';

const resources = {
  fr: {
    translation: translationFrench,
  },
  en: {
    translation: translationEnglish,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
    resources: resources,
  });

export default i18n;
