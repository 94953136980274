import React from 'react';
import FlexBox from '../../atoms/FlexBox/FlexBox';
import { withTranslation, WithTranslation } from 'react-i18next';

import './_Monetization.scss';
import illustration from '../../../assets/illustrations/agenda.svg';
import Text from '../../atoms/Text/Text';
import Title from '../../atoms/Title/Title';

interface MonetizationProps extends WithTranslation {}

const Monetization: React.FunctionComponent<MonetizationProps> = ({ t }) => {
  return (
    <FlexBox className="container--responsive">
      <FlexBox
        className="monetization_wrapper p-1"
        flexDirection="row-reverse"
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexWrap="wrap"
      >
        <FlexBox
          className="monetization_left"
          flexDirection="column"
          width="100%"
        >
          <Title className="color--primary text-left" size="large" variant="h2">
            {t('component.monetization.title')}
          </Title>
          <Text
            className="color--tertiary text-left mt-1"
            size="small"
            lighting="light"
          >
            {t('component.monetization.description')}
          </Text>
        </FlexBox>
        <FlexBox
          className="monetization_right"
          flexDirection="column"
          width="100%"
        >
          <img
            className="monetization_illustration"
            src={illustration}
            alt="illustration monetization"
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(Monetization);
