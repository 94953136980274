import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import FlexBox from '../../atoms/FlexBox/FlexBox';
import Text from '../../atoms/Text/Text';
import Title from '../../atoms/Title/Title';
import './_subHeader.scss';

interface SubHeaderProps extends WithTranslation {}

const SubHeader: React.FunctionComponent<SubHeaderProps> = ({ t }) => {
  return (
    <FlexBox className="container--responsive">
      <FlexBox
        className="subHeader_wrapper p-1 mt-2"
        flexDirection="column"
        width="100%"
      >
        <Title
          className="subHeader_title color--primary text-left"
          variant="h1"
          size="large"
          lighting="light"
        >
          <span className="color--primary">
            {t('component.subHeader.title.first')}{' '}
          </span>
          <span className="color--ecology">
            {t('component.subHeader.title.second')}
          </span>{' '}
          <span className="color--primary">
            {t('component.subHeader.title.third')}{' '}
          </span>
          <span className="color--ecology">
            {t('component.subHeader.title.fourth')}
          </span>
        </Title>
        <FlexBox
          className="subHeader_box_dontPlay mt-2"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Title
            className="heading--medium--extralight color--primary"
            variant="h3"
            size="medium"
            lighting="extralight"
          >
            {t('component.subHeader.subTitle.dontPlay')}
          </Title>
          <span className="heading--medium--extralight color--rainbow">
            {t('component.subHeader.subTitle.withNature')}
          </span>
        </FlexBox>
        <Text
          className="subHeader_description color--tertiary text-left mt-1"
          size="small"
          lighting="light"
        >
          {t('component.subHeader.description')}
        </Text>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(SubHeader);
