/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import FlexBox from '../../atoms/FlexBox/FlexBox';
import { withTranslation, WithTranslation } from 'react-i18next';
import illustration from '../../../assets/illustrations/planification.svg';
import './_planification.scss';
import Text from '../../atoms/Text/Text';
import Title from '../../atoms/Title/Title';

interface planificationProps extends WithTranslation {}

const Planification: React.FunctionComponent<planificationProps> = ({ t }) => {
  return (
    <FlexBox className="container--responsive">
      <FlexBox
        className="planification_wrapper p-1"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexWrap="wrap"
      >
        <FlexBox
          className="planification_left"
          flexDirection="column"
          width="100%"
        >
          <Title
            className=" color--primary text-left"
            variant="h2"
            size="large"
          >
            {t('component.planification.title')}
          </Title>
          <Text
            className="color--tertiary text-left mt-1"
            size="small"
            lighting="light"
          >
            {t('component.planification.description')}
          </Text>
        </FlexBox>
        <FlexBox
          className="planification_right"
          flexDirection="column"
          width="100%"
        >
          <img
            className="planification_illustration"
            src={illustration}
            alt="illustration planification"
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(Planification);
