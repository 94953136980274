import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FlexBox from '../../components/atoms/FlexBox';
import Title from '../../components/atoms/Title/Title';
import Text from '../../components/atoms/Text/Text';
import Card from '../../components/atoms/Card';
import Accordion, {
  AccordionProps,
} from '../../components/atoms/Accordion/Accordion';
import './_faq.scss';

interface FaqProps extends WithTranslation {}

type CardProps = { title: string; description: string };

const Faq: React.FC<FaqProps> = ({ t }) => {
  const [faqs] = useState<AccordionProps[]>([
    {
      question: 'component.faq.firstQuestion',
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      question: 'component.faq.secondQuestion',
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      question: 'component.faq.thirdQuestion',
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      question: 'component.faq.fourthQuestion',
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
  ]);
  const [cards] = useState<CardProps[]>([
    {
      title: 'component.faq.firstQuestion',
      description:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      title: 'component.faq.secondQuestion',
      description:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      title: 'component.faq.thirdQuestion',
      description:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
  ]);

  return (
    <FlexBox
      className="faq p-1 container--responsive mb-5"
      alignItems="center"
      flexDirection="column"
    >
      <Title
        className="title color--primary text-left"
        size="extralarge"
        variant="h1"
      >
        {t('component.faq.bestOffers')}
      </Title>
      <Title
        className="title faq_title_for_you color--rainbow text-left"
        size="extralarge"
        variant="h1"
        width="100%"
      >
        {t('component.faq.forYou')}
      </Title>
      <Text
        className="faq_description_page color--tertiary mt-1 text-left"
        size="medium"
        lighting="extralight"
        width="100%"
      >
        {t('component.faq.description')}
      </Text>
      <FlexBox
        className="background--rainbow mt-3 mb-3"
        height="2px"
        width="80%"
      ></FlexBox>
      <FlexBox
        className="faq_cards_list_security"
        width="100%"
        flexDirection="column"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        gap="20px"
      >
        {cards.map((card: CardProps, index: number) => {
          return (
            <Card
              key={index}
              className="faq_card background--primary"
              height="250px"
              width="300px"
            >
              <FlexBox
                className="card_right p-2"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                gap="10px"
              >
                <FlexBox
                  className="faq_box_check"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  height="100%"
                >
                  <span style={{ fontSize: '15px' }}>☑️</span>
                </FlexBox>
                <FlexBox flexDirection="column" height="100%">
                  <Text size="small">{t(card.title)}</Text>
                  <Text size="small" lighting="light">
                    {t('component.faq.yourEncryptedData')}
                  </Text>
                </FlexBox>
              </FlexBox>
            </Card>
          );
        })}
      </FlexBox>

      <FlexBox className="wrapper_faq_accordion p-1" flexDirection="column">
        {faqs
          ? faqs.map((faq, index) => {
              return (
                <Accordion
                  width="100%"
                  key={index}
                  question={t(faq.question)}
                  answer={t(faq.answer)}
                ></Accordion>
              );
            })
          : null}
      </FlexBox>
      <FlexBox className="faq_cta_wrapper">
        <Card className="background--primary pt-1 pb-1 pl-3 pr-3 mt-1">
          <FlexBox flexDirection="column" gap="10px">
            <Text className="text-left" size="medium">
              {t('component.faq.anotherQuestion')}
            </Text>
            <Text size="small" lighting="light">
              {t('component.faq.dontHesitate')}
            </Text>
          </FlexBox>
        </Card>
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(Faq);
