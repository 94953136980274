import React, { useState } from 'react';

import '../../../sass/components/_accordion.scss';
import Text from '../Text/Text';

export type AccordionProps = {
  width?: string;
  question: string;
  answer: string;
};

const Accordion: React.FunctionComponent<AccordionProps> = ({
  width,
  question,
  answer,
}) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`accordion ${active ? 'active' : ''}`}
      style={{ width: width }}
      data-testid="accordion"
    >
      <div className="accordion__title" onClick={() => setActive(!active)}>
        <Text
          className="accordion__title color--primary text-left"
          size="medium"
          lighting="light"
        >
          {question}
        </Text>

        <div className={`accordion__icon color--primary`}>
          {active ? <i>-</i> : <i>+</i>}
        </div>
      </div>
      <div
        className={`accordion__content color--primary`}
        data-testid="accordion-content"
      >
        <Text size="small" lighting="light">
          {answer}
        </Text>
      </div>
    </div>
  );
};

export default Accordion;
