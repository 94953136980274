import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Accordion, { AccordionProps } from '../../atoms/Accordion/Accordion';
import FlexBox from '../../atoms/FlexBox/FlexBox';
import Title from '../../atoms/Title/Title';

interface FaqAccordionProps extends WithTranslation {}

const FaqAccordion: React.FunctionComponent<FaqAccordionProps> = ({ t }) => {
  const [faqs] = useState<AccordionProps[]>([
    {
      question: t('component.faq.firstQuestion'),
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      question: t('component.faq.secondQuestion'),
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      question: t('component.faq.thirdQuestion'),
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
    {
      question: t('component.faq.fourthQuestion'),
      answer:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
    },
  ]);
  return (
    <FlexBox className="p-1" flexDirection="column">
      <Title
        className="heading--large color--primary text-center"
        size="large"
        variant="h2"
      >
        {t('component.faq.title')}
      </Title>
      <FlexBox
        className="wrapper_faq_accordion"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {faqs
          ? faqs.map((faq, index) => {
              return (
                <Accordion
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                ></Accordion>
              );
            })
          : null}
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(FaqAccordion);
