import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import FlexBox from '../../atoms/FlexBox/FlexBox';

import illustration from '../../../assets/illustrations/connect-network.svg';
import './_connectYourPosts.scss';
import Text from '../../atoms/Text/Text';
import Title from '../../atoms/Title/Title';

interface ConnectYourPostsProps extends WithTranslation {}

const ConnectYourPosts: React.FunctionComponent<ConnectYourPostsProps> = ({
  t,
}) => {
  return (
    <FlexBox className="container--responsive">
      <FlexBox className="connectYourPost_wrapper p-1" flexDirection="column">
        <Title
          className="connectYourPost_title color--primary text-left"
          size="large"
          variant="h2"
        >
          {t('component.connectYourPosts.title')}
        </Title>
        <Text
          className="connectYourPost_description color--tertiary text-left mt-1"
          size="small"
          lighting="light"
        >
          {t('component.connectYourPosts.description')}
        </Text>
        <img
          className="connectYourPost_illustration"
          src={illustration}
          alt="illustration"
        />
      </FlexBox>
    </FlexBox>
  );
};

export default withTranslation()(ConnectYourPosts);
