import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import FlexBox from '../../atoms/FlexBox/FlexBox';
import Text from '../../atoms/Text/Text';

interface TestBannerProps extends WithTranslation {}

const TestBanner: React.FunctionComponent<TestBannerProps> = ({ t }) => {
  return (
    <FlexBox
      className="background--warning"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="auto"
    >
      {process.env.REACT_APP_NODE_ENV === 'test' ? (
        <Text className="text-center" size="small">
          {t('🚔 Vous êtes sur la QA actuellement (Test Mode)')}
        </Text>
      ) : (
        <Text className="text-center" size="small">
          {t('🚔 Vous êtes en local actuellement (Development Mode)')}
        </Text>
      )}
    </FlexBox>
  );
};

export default withTranslation()(TestBanner);
