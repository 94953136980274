import React from 'react';

import '../../../sass/components/_cards.scss';

type CardProps = {
  width?: string;
  height?: string;
  className?: string;
  children?: React.ReactNode;
};

const Card: React.FC<CardProps> = ({
  className,
  children,
  width = '100%',
  height,
}) => {
  return (
    <div
      className={`card ${className}`}
      style={{ width: width, height: height }}
    >
      {children}
    </div>
  );
};

export default Card;
