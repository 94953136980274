import React from 'react';

type TextProps = {
  className?: string;
  size: 'large' | 'medium' | 'small';
  lighting?: 'light' | 'extralight';
  width?: string;
  children?: React.ReactNode;
};

const Text: React.FC<TextProps> = ({
  className,
  size,
  lighting,
  width,
  children,
}) => {
  return (
    <span
      className={
        lighting && size
          ? `text--${size}--${lighting} ${className}`
          : `text--${size} ${className}`
      }
      style={{ width: width }}
    >
      {children}
    </span>
  );
};

export default Text;
